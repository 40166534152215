<template>
  <div>
    <div>
      <NavBar />
      <slot />
    </div>
  </div>
</template>

<script>
const NavBar = () => import("./includes/Navbar");
export default {
  name: "navigation",
  components: {
    NavBar
  }
};
</script>

<style>
</style>